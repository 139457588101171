import { createSlice } from "@reduxjs/toolkit";
import { NotificationCountApi } from "../../services/Api";

const initialState = {
  value: 0,
  userData: {},
  notificationCount: 0,
  invoiceLogImgCount:0,
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      // console.log("state", state);
      // console.log("action", action);
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state, action) => {
      return (state = {
        ...state,
        userData: {},
      });
    },
    NotificationAction: (state, action) => {
      // console.log("notification", action.payload);
      return (state = {
        ...state,
        notificationCount: action.payload,
      });
    },
    InvoiceLogsImgAction: (state, action) => {
      // console.log("invoiceLogImgCount redux state = ", state);
      // console.log("invoiceLogImgCount redux = ", action.payload);
      return (state = {
        ...state,
        invoiceLogImgCount: action.payload,
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginAction, logoutAction, NotificationAction, InvoiceLogsImgAction } =
  authSlice.actions;

export const loginData = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    console.log(error);
  }
};

export const logoutData = () => async (dispatch) => {
  try {
    dispatch(logoutAction());
  } catch (error) {
    console.log(error);
  }
};

export const NotificationData = (setIsError) => async (dispatch) => {
  try {
    NotificationCountApi()
      .then((res) => {
        // console.log('Notification Count = ', res)
        dispatch(NotificationAction(res?.data?.result));
        setIsError(true);
      })
      .catch((err) => {
        console.log({ Message: "Redux api is not working.", Error: err });
      });
  } catch (error) {
    console.log({ Message: "Redux Api is not working.", Error: error });
  }
};

export const InvoiceLogImgData = (data) => async (dispatch) => {
  try {
    dispatch(InvoiceLogsImgAction(data));
  } catch (error) {
    console.log(error);
  }
};

export const selectUserData = (state) => state.auth.userData;
export const selectNotificationData = (state) => state.auth.notificationCount;
export const selectInvoiceLogImgData = (state) => state.auth.invoiceLogImgCount;

export default authSlice.reducer;
